<template>
  <div
    v-if="showModal"
    class="relative w-full sm:w-fit bg-[#e6cd90] border-4 border-[#a3a3a317] rounded-lg p-20 fade"
  >
    <img
      :src="BG"
      height="200"
      width="128"
      class="absolute -bottom-4 -right-4 shrink-0 h-[360px] max-w-1/2 sm:max-w-[none] w-auto object-contain object-right-bottom rounded-lg"
    />

    <div class="sm:mr-256 max-w-1/2 sm:max-w-[none]">
      <div
        class="relative text-14 md:text-16 sm:max-w-[192px] md:max-w-[220px] mb-16 lg:mb-40"
      >
        {{ $t("referral_modal.text") }}
      </div>

      <div class="flex flex-col gap-16">
        <SharedLink url="/referrals">
          <UiButton size="tiny" class="w-fit" @click="seen()">{{
            $t("referral_modal.yes")
          }}</UiButton>
        </SharedLink>

        <UiButton size="tiny" class="w-fit" @click="seen()">{{
          $t("referral_modal.no")
        }}</UiButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BG from "~/assets/img/referrals/referrals-modal.webp";

const referralModalCookie = useCookie("referral_modal_seen", {
  maxAge: 7760000,
});

const route = useRoute();
const isOnReferralsPage = computed(() => route.fullPath.includes("referral"));

const interacted = ref(false);
function seen() {
  referralModalCookie.value = "yes";
  interacted.value = true;
}

const showModal = computed(() => !interacted.value && !isOnReferralsPage.value);
</script>

<style></style>
