<template>
  <div class="flex flex-col items-center lg:mx-0 bg-beige">
    <Header />

    <FloatingLogo />

    <div class="flex-grow w-full min-h-screen overflow-x-hidden">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </div>

    <CookieModal />

    <div
      class="fixed left-8 sm:left-[unset] bottom-8 sm:bottom-16 right-8 sm:right-16 flex flex-col gap-8 items-end sm:w-fit z-[99]"
    >
      <ReferralModal v-if="showReferralModal" />

      <FloatingCallBanner />
    </div>

    <Footer />
  </div>
</template>

<script setup lang="ts">
import { setHead } from "~/lib/head";

import "swiper/css";
import "~/assets/css/tailwind.scss";

setHead("landingpage");

const showReferralModal = ref(false);
const referralModalCookie = useCookie("referral_modal_seen", {
  maxAge: 7760000,
});
onMounted(() => {
  if (referralModalCookie.value !== "yes") {
    showReferralModal.value = true;
  }
});
</script>
