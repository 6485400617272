<template>
  <header
    class="fixed right-0 z-[500] -mb-64 sm:-mb-[110px] fade-in-move-down"
    :class="[
      showMobileMenu
        ? 'top-0 w-full max-w-full bg-overlay backdrop-blur-md h-screen'
        : 'top-8 md:top-20',
    ]"
  >
    <nav
      class="flex flex-col items-end px-8 lg:px-20"
      :class="showMobileMenu && 'py-8 w-full'"
    >
      <div
        class="flex h-48"
        :class="showMobileMenu && 'justify-end w-full bg-beige rounded-t-lg'"
      >
        <div
          class="flex items-center gap-16 w-fit text-14 px-8 py-6 sm:py-4 sm:px-4 rounded-lg sm:rounded-full bg-beige border-4"
          :class="showMobileMenu ? 'border-transparent' : 'border-[#a3a3a317]'"
        >
          <div
            class="hidden sm:flex items-center gap-16 w-fit text-14 px-8 py-6 sm:p-0"
            :class="
              showMobileMenu ? 'border-transparent' : 'border-[#a3a3a317]'
            "
          >
            <div class="flex items-center gap-24">
              <div class="flex justify-center">
                <div class="flex items-center gap-2">
                  <SharedLink
                    v-for="link in headerLinks"
                    :key="link.label"
                    :url="link.url"
                    :hash="link.hash"
                    class="py-8 px-16 hover:bg-[#00000008] rounded-full transition-all"
                    :class="link.url === '/contact' && 'sm:hidden md:flex'"
                  >
                    {{ link.label }}
                  </SharedLink>
                </div>
              </div>
            </div>
          </div>

          <div
            class="hidden sm:flex justify-center items-center md:justify-end gap-16 -mt-4 ml-16"
          >
            <LanguageSwitcher class="mt-4" />
          </div>

          <div class="flex items-center gap-16 sm:hidden">
            <LanguageSwitcher v-if="showMobileMenu" class="mr-16 text-16" />

            <div class="h-24 w-24" @click="showMobileMenu = !showMobileMenu">
              <IconsIconMenu v-if="!showMobileMenu" class="h-full w-full" />

              <IconsIconClose v-else class="h-full w-full" />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showMobileMenu"
        class="md:hidden bg-beige rounded-b-lg pt-24 border-t-4 border-[#968b7417] w-full"
      >
        <div class="flex flex-col gap-20 px-24">
          <SharedLink
            v-for="link in headerLinks"
            :key="link.label"
            :url="link.url"
            :hash="link.hash"
            class="w-fit text-20"
            @click="showMobileMenu = false"
          >
            {{ link.label }}
          </SharedLink>

          <SharedMeetingLink class="sm:hidden text-14 mt-24 mb-32">
            <UiButton size="small" class="w-full"> Beraten lassen </UiButton>
          </SharedMeetingLink>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
const showMobileMenu = ref(false);

const { t } = useNuxtApp().$i18n;
const headerLinks = computed(() => [
  {
    url: "/",
    label: t("header.links.concept"),
    hash: "#concept",
  },
  {
    url: "/",
    label: t("header.links.services"),
    hash: "#services",
  },
  {
    url: "/",
    label: t("header.links.pricing"),
    hash: "#pricing",
  },
  {
    url: "/referrals",
    label: t("header.links.200"),
    hash: "",
  },
  {
    url: "/contact",
    label: t("header.links.contact"),
    hash: "",
  },
]);
</script>

<style lang="scss">
.fade-in-move-down {
  opacity: 0;
  transform: translateY(-30px);
  animation: fadeInMoveDown 0.35s ease-out forwards;
}

@keyframes fadeInMoveDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
