<template>
  <div
    v-if="show && !$route.fullPath.includes('/contact')"
    class="fade flex justify-between items-center w-full sm:w-fit text-14 bg-[#e6cd90] border-4 border-[#a3a3a317] p-4 rounded-full"
  >
    <div class="flex items-center sm:mr-40">
      <img
        :src="hans"
        class="shrink-0 h-40 w-40 sm:h-[52px] sm:w-[52px] rounded-full object-cover object-top"
      />

      <div>
        <div class="shrink-0 text-16 ml-12 sm:ml-20 leading-xs sm:leading-s">
          <div class="font-bold">Hans Fuchs</div>
          <div class="text-14">Growth & Strategy</div>
        </div>
      </div>
    </div>

    <SharedLink url="/contact">
      <UiButton size="small" class="hidden sm:flex">
        {{ $t("call_banner.cta") }}
      </UiButton>
      <UiButton size="tiny" class="sm:hidden">
        {{ $t("call_banner.cta") }}
      </UiButton>
    </SharedLink>
  </div>
</template>

<script lang="ts" setup>
import hans from "~/assets/img/hans.webp";

const scrollY = ref(0);

// Function to update the scroll position
const updateScroll = () => {
  scrollY.value = window.scrollY;
};

// Computed property to check if scrolled past 90px
const show = computed(() => scrollY.value >= 90);

// Add event listener when component is mounted
onMounted(() => {
  window.addEventListener("scroll", updateScroll);
});

// Remove event listener when component is unmounted
onUnmounted(() => {
  window.removeEventListener("scroll", updateScroll);
});
</script>

<style></style>
