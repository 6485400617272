<template>
  <div class="relative">
    <div ref="anchor" class="cursor-pointer" @click="handleClick()">
      <slot name="anchor"></slot>
    </div>

    <div
      ref="tooltipContainer"
      class="hidden absolute top-[calc(100%+12px)] right-0 z-[250] p-2 drop-shadow-sm text-14 min-w-128 w-[max-content] max-w-[280px] opacity-0 rounded-lg bg-beige border-4 border-[#a3a3a317]"
      :class="[show && 'fade-in', !show && !pristine && 'fade-out']"
    >
      <div
        v-if="hasArrow"
        ref="floatingArrow"
        class="h-20 w-20 rotate-45 bg-beige"
        :style="{
          position: 'absolute',
          left:
            middlewareData.arrow?.x != null
              ? `${middlewareData.arrow.x}px`
              : '',
          top: '-1px',
        }"
      ></div>

      <div class="relative" @click="show = false">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { arrow, offset, useFloating } from "@floating-ui/vue";
import { captureOutsideClick, removeClickListener } from "~/lib/click";

const props = defineProps<{
  hasArrow: boolean;
}>();

const anchor = ref<HTMLElement | null>(null);
const tooltipContainer = ref<HTMLElement | null>(null);
const floatingArrow = ref<HTMLElement | null>(null);
const { middlewareData } = useFloating(anchor, tooltipContainer, {
  middleware: [
    offset(10),
    ...(props.hasArrow ? [arrow({ element: floatingArrow, padding: 10 })] : []),
  ],
});

const pristine = ref(true);
const show = ref(false);
const timeout = ref<NodeJS.Timeout | undefined>(undefined);

async function handleClick() {
  show.value = false;
  clearTimeout(timeout.value);
  await nextTick();

  show.value = true;
  pristine.value = false;
}

onUnmounted(() => {
  show.value = false;
});

watch(show, (newValue) => {
  if (newValue) {
    captureOutsideClick({
      toggle: anchor.value,
      targetElement: tooltipContainer.value,
      callbackFn: () => (show.value = false),
    });
  } else {
    removeClickListener();
  }
});
</script>

<style lang="scss">
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
  }
}

.fade-in {
  display: block;
  animation: fadeIn 0.12s ease-out forwards;
}

.fade-out {
  animation: fadeOut 0.12s ease-out forwards;
}
</style>
