import { onBeforeUnmount, type Ref, watch } from "vue";

export function clickInsideOf(event: MouseEvent, parent: any, toggle?: any) {
  if (!parent) {
    return false;
  }

  const rootElement = document.documentElement;
  let currentElement: HTMLElement | null = event.target as HTMLElement;
  const expectedParent: HTMLElement = parent.$el || parent;

  while (currentElement) {
    // exception for disappearing elements
    if (currentElement !== rootElement && currentElement.parentElement === null)
      return true;

    // exception for image viewer
    if (currentElement.classList.contains("viewer-canvas")) return true;

    if (
      currentElement === expectedParent ||
      currentElement.parentElement === expectedParent
    )
      return true;

    if (!!toggle && currentElement === toggle) return true;

    currentElement = currentElement.parentElement;
  }

  return false;
}

export function captureOutsideClick(options: {
  toggle?: HTMLElement | null;
  targetElement: HTMLElement | null;
  callbackFn: () => any;
}) {
  if (options.toggle === null) {
    return;
  }

  if (!options.targetElement) {
    return;
  }

  (window as any).captureClick = function captureClick(event: MouseEvent) {
    if (!clickInsideOf(event, options.targetElement, options.toggle)) {
      document.removeEventListener("click", captureClick);
      options.callbackFn();
    }
  };

  document.addEventListener("click", (window as any).captureClick);
}

export function handleClickBehavior({
  toggle,
  target,
  callbackFn,
}: {
  toggle: Ref<HTMLElement | null>;
  target: Ref<HTMLElement | null>;
  callbackFn: () => void;
}) {
  watch(target, (newValue, oldValue) => {
    if (newValue) {
      captureOutsideClick({
        toggle: toggle.value,
        targetElement: newValue,
        callbackFn,
      });
    } else {
      removeClickListener();
    }
  });

  onBeforeUnmount(() => {
    callbackFn();
    removeClickListener();
  });
}

export function removeClickListener() {
  document.removeEventListener("click", (window as any).captureClick);
}
