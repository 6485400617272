import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as free_45reportiu0SjfqOwEMeta } from "/vercel/path0/pages/free-report.vue?macro=true";
import { default as imprintiuNFFLYTouMeta } from "/vercel/path0/pages/imprint.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as referralsicwDbnmRCcMeta } from "/vercel/path0/pages/referrals.vue?macro=true";
import { default as successW4sLGie1V5Meta } from "/vercel/path0/pages/success.vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: free_45reportiu0SjfqOwEMeta?.name ?? "free-report",
    path: free_45reportiu0SjfqOwEMeta?.path ?? "/free-report",
    meta: free_45reportiu0SjfqOwEMeta || {},
    alias: free_45reportiu0SjfqOwEMeta?.alias || [],
    redirect: free_45reportiu0SjfqOwEMeta?.redirect,
    component: () => import("/vercel/path0/pages/free-report.vue").then(m => m.default || m)
  },
  {
    name: imprintiuNFFLYTouMeta?.name ?? "imprint",
    path: imprintiuNFFLYTouMeta?.path ?? "/imprint",
    meta: imprintiuNFFLYTouMeta || {},
    alias: imprintiuNFFLYTouMeta?.alias || [],
    redirect: imprintiuNFFLYTouMeta?.redirect,
    component: () => import("/vercel/path0/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQl0qlywOJTMeta?.name ?? "privacy-policy",
    path: privacy_45policyQl0qlywOJTMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyQl0qlywOJTMeta || {},
    alias: privacy_45policyQl0qlywOJTMeta?.alias || [],
    redirect: privacy_45policyQl0qlywOJTMeta?.redirect,
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: referralsicwDbnmRCcMeta?.name ?? "referrals",
    path: referralsicwDbnmRCcMeta?.path ?? "/referrals",
    meta: referralsicwDbnmRCcMeta || {},
    alias: referralsicwDbnmRCcMeta?.alias || [],
    redirect: referralsicwDbnmRCcMeta?.redirect,
    component: () => import("/vercel/path0/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: successW4sLGie1V5Meta?.name ?? "success",
    path: successW4sLGie1V5Meta?.path ?? "/success",
    meta: successW4sLGie1V5Meta || {},
    alias: successW4sLGie1V5Meta?.alias || [],
    redirect: successW4sLGie1V5Meta?.redirect,
    component: () => import("/vercel/path0/pages/success.vue").then(m => m.default || m)
  },
  {
    name: termsq5qlZHSSonMeta?.name ?? "terms",
    path: termsq5qlZHSSonMeta?.path ?? "/terms",
    meta: termsq5qlZHSSonMeta || {},
    alias: termsq5qlZHSSonMeta?.alias || [],
    redirect: termsq5qlZHSSonMeta?.redirect,
    component: () => import("/vercel/path0/pages/terms.vue").then(m => m.default || m)
  }
]