<template>
  <Teleport v-if="essentialCookie !== 'yes'" to="body">
    <ClientOnly>
      <div class="fixed h-screen w-screen bg-overlay p-8 z-[999]">
        <div
          class="fixed left-16 md:left-[unset] bottom-16 right-16 md:max-w-[620px] bg-beige-light rounded-lg sm:rounded-xl p-24 sm:p-40 shadow-lg"
        >
          <div
            class="flex flex-col justify-between gap-32 max-h-full overflow-auto"
          >
            <div>
              <i18n-t
                keypath="cookies.text"
                tag="div"
                class="relative text-12 sm:text-14 tracking-wide max-w-512 sm:max-w-none"
              >
                <span
                  class="clickable-text font-bold"
                  @click="acceptRequired()"
                  >{{ $t("cookies.choose_essential") }}</span
                >

                <SharedLink
                  url="/privacy-policy"
                  :inline="true"
                  class="underline font-bold"
                  >{{ $t("cookies.privacy") }}</SharedLink
                >
              </i18n-t>

              <div
                v-if="customize"
                class="flex flex-col sm:flex-row gap-16 sm:gap-24 my-24"
              >
                <UiCheckbox :selected="true">
                  {{ $t("cookies.essential") }}
                </UiCheckbox>

                <UiCheckbox v-model:selected="analyticsCookieAccepted">
                  {{ $t("cookies.analytics") }}
                </UiCheckbox>
              </div>
            </div>

            <div class="flex justify-end mt-32 sm:mt-0 sm:mb-8 w-full">
              <div
                class="flex flex-col sm:flex-row-reverse items-center text-16 gap-20 w-full sm:w-fit"
              >
                <UiButton class="w-full sm:w-fit" @click="acceptAll()">{{
                  $t("cookies.accept_all")
                }}</UiButton>

                <div class="clickable-text" @click="customize = !customize">
                  <div v-if="customize" @click="acceptSelected()">
                    {{ $t("cookies.accept_selected") }}
                  </div>

                  <div v-else>{{ $t("cookies.customize") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ClientOnly>
  </Teleport>
</template>

<script lang="ts" setup>
const customize = ref(false);

const essentialCookie = useCookie("essential_cookies_accepted", {
  maxAge: 7760000,
});
const analyticsCookie = useCookie("analytics_cookies_accepted", {
  maxAge: 7760000,
});

const analyticsCookieAccepted = ref(true);

onMounted(() => {
  if (essentialCookie.value === "yes") return;

  preventScrolling();

  essentialCookie.value = "no";
  analyticsCookie.value = "no";
});
watch(essentialCookie, (newValue) => {
  if (newValue !== "yes") {
    preventScrolling();
  }
});

function acceptRequired() {
  essentialCookie.value = "yes";

  restoreScrolling();
}

function acceptSelected() {
  analyticsCookie.value = analyticsCookieAccepted.value ? "yes" : "no";
  essentialCookie.value = "yes";

  restoreScrolling();
}

function acceptAll() {
  analyticsCookie.value = "yes";
  essentialCookie.value = "yes";

  restoreScrolling();
}

function preventScrolling() {
  document.documentElement.style.overflow = "hidden";
}

function restoreScrolling() {
  document.documentElement.style.overflow = "auto";
}
</script>

<style></style>
