<template>
  <SharedLink
    url="/"
    class="group fixed top-8 md:top-20 left-8 md:left-20 flex items-center w-fit transition-transform z-[501]"
  >
    <IconsIconLogoNotext
      class="h-48 w-48 bg-beige rounded-full border-2 border-[#968b7417]"
    />
  </SharedLink>
</template>

<script lang="ts" setup></script>

<style></style>
