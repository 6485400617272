<template>
  <div
    class="flex items-center gap-8 hover:cursor-pointer select-none"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
    @click="emit('update:selected', !selected)"
  >
    <div
      class="flex items-center justify-center h-20 w-20 border-2 shadow-lg border-light rounded-sm"
    >
      <div
        v-if="selected"
        class="shrink-0 h-12 w-12 bg-dark rounded-[2px]"
      ></div>
    </div>

    <span v-if="$slots.default" class="leading-xs text-14">
      <slot></slot>
    </span>
  </div>
</template>

<script setup lang="ts">
defineProps({
  selected: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:selected"]);

const hovering = ref(false);
</script>
