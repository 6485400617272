export function setHead(key: string) {
  const img = `https://hyperfocused.agency/img/opengraph-${key}.webp`;

  setMetaTags(key, img);

  watch(
    () => useCookie("i18n_redirected").value,
    () => {
      setMetaTags(key, img);
    }
  );
}

function setMetaTags(key: string, image?: string) {
  const title = useNuxtApp().$i18n.t(`meta.${key}.title`);
  const description = useNuxtApp().$i18n.t(`meta.${key}.description`);

  useSeoMeta({
    title,
    ogTitle: title,
    description,
    ogDescription: description,
    ogImage: image,
    twitterCard: "summary_large_image",
  });
}
