<template>
  <div v-if="!url">
    <slot></slot>
  </div>

  <a
    v-else-if="['http', 'mailto', 'tel'].some((v) => url.startsWith(v))"
    :href="url"
    target="_blank"
    :class="style"
  >
    <slot></slot>
  </a>

  <NuxtLink
    v-else
    :to="{
      path: url,
      hash: hash || '',
      query: query || {},
    }"
    :class="style"
  >
    <slot></slot>
  </NuxtLink>
</template>

<script setup lang="ts">
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  underline: {
    type: Boolean,
    default: false,
  },
  query: {
    type: Object as PropType<any>,
    default: undefined,
  },
  hash: {
    type: String,
    default: undefined,
  },
  inline: {
    type: Boolean,
    default: false,
  },
});

const style = computed(() => [
  props.inline ? "" : "flex items-center gap-12",
  props.underline && "hover:underline",
]);
</script>
