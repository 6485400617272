<template>
  <svg viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M70 140C31.3401 140 -4.74968e-06 108.66 -3.0598e-06 70C-1.36992e-06 31.3401 31.3401 -4.74968e-06 70 -3.0598e-06C108.66 -1.36992e-06 140 31.3401 140 70C140 108.66 108.66 140 70 140ZM122.979 65.842C124.858 68.3617 124.855 71.7156 122.97 74.2309C115.462 84.2505 94.5828 108.763 70.0002 108.763C45.4177 108.764 24.5385 84.2513 17.0309 74.2319C15.1461 71.7166 15.1426 68.3626 17.0215 65.8429C24.5213 55.7858 45.4081 31.1413 70.0009 31.1411C94.5936 31.1409 115.48 55.785 122.979 65.842ZM33 70C33 90.4346 49.5655 107 70 107C90.4345 107 107 90.4346 107 70C107 49.5655 90.4345 33 70 33C49.5655 33 33 49.5655 33 70Z"
      fill="currentColor"
    />
  </svg>
</template>
