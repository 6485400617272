<template>
  <button
    class="relative flex justify-center items-center gap-12 font-body font-bold leading-xs border-2 rounded-full select-none whitespace-nowrap"
    :class="[
      variant === 'primary' ? 'hyper-button' : 'hyper-button-secondary',
      size === 'default'
        ? 'px-48 py-16 sm:py-20 text-[18px]'
        : size === 'small'
        ? 'px-24 py-12 text-16 hyper-button-small'
        : size === 'tiny'
        ? 'px-16 py-8 text-14 hyper-button-tiny'
        : '',
      disabled || loading ? ' pointer-events-none' : 'cursor-pointer',
      loading && 'loading',
    ]"
    style="transition: all 0.2s ease 0s"
    :disabled="disabled || loading"
    @click="onClick"
  >
    <slot v-if="$slots.default"></slot>

    <div
      v-if="loading"
      class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
    >
      <IconsIconLoading
        class="h-20 w-20 animate-spin"
        :class="[variant === 'primary' ? 'text-dark' : 'text-[#7d7c8a]']"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
const props = defineProps({
  type: {
    type: String as PropType<"submit" | "button">,
    default: "button",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String as PropType<"primary" | "secondary">,
    default: "primary",
  },
  size: {
    type: String as PropType<"default" | "small" | "tiny">,
    default: "default",
  },
});

const emit = defineEmits(["click"]);

function onClick($event: MouseEvent) {
  if (props.disabled || props.loading) {
    $event.stopPropagation();
  }

  emit("click", $event);
}
</script>

<style lang="scss">
.hyper-button {
  @apply bg-[#ffffff21] backdrop-blur-[4px] border-dark text-dark mb-8;
  box-shadow: 0px 8px 0px 0px;

  &:hover {
    box-shadow: 0px 3px 0px 0px;
    transform: translateY(5px);
  }
}
.group:hover .hyper-button {
  box-shadow: 0px 3px 0px 0px;
  transform: translateY(5px);
}

.hyper-button-secondary {
  @apply bg-[#ffffff21] backdrop-blur-[4px] text-beige mb-8;
  box-shadow: 0px 8px 0px 0px #efe2c5;

  &:hover {
    box-shadow: 0px 3px 0px 0px #efe2c5;
    transform: translateY(5px);
  }
}
.group:hover .hyper-button-secondary {
  box-shadow: 0px 3px 0px 0px #efe2c5;
  transform: translateY(5px);
}

.hyper-button-small {
  margin-bottom: 6px !important;
  box-shadow: 0px 6px 0px 0px;

  &:hover {
    box-shadow: 0px 2px 0px 0px;
    transform: translateY(4px);
  }
}
.group:hover .hyper-button-small {
  box-shadow: 0px 6px 0px 0px;
  transform: translateY(4px);
}

.hyper-button-tiny {
  margin-bottom: 4px !important;
  box-shadow: 0px 4px 0px 0px;

  &:hover {
    box-shadow: 0px 2px 0px 0px;
    transform: translateY(2px);
  }
}
.group:hover .hyper-button-tiny {
  box-shadow: 0px 2px 0px 0px;
  transform: translateY(2px);
}

.loading {
  color: transparent !important;
  box-shadow: none;
}
</style>
