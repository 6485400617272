export default defineNuxtPlugin((nuxtApp) => ({
  provide: {
    track: (event: string, options?: any) => {
      const analyticsCookie = useCookie("analytics_cookies_accepted");
      if (analyticsCookie.value !== "yes") return;

      if (import.meta.env.VITE_ENV === "dev") {
        console.log("plausible:", event, options);
        return;
      }

      useTrackEvent(event, options);
    },
  },
}));
