<template>
  <UiDropdown :has-arrow="false">
    <template #anchor>
      <div
        class="flex items-center gap-8 select-none hover:bg-[#00000008] px-16 py-8 rounded-full"
      >
        <IconsIconGlobe class="h-20 w-20" />

        {{ $i18n.locale === "en" ? "English (US)" : "Deutsch (DE)" }}
      </div>
    </template>

    <template #content>
      <div class="flex flex-col gap-2">
        <div
          class="px-16 py-8 hover:bg-[#00000008] rounded-full cursor-pointer"
          :class="[$i18n.locale === 'en' && 'font-bold']"
          @click="updateLocale('en')"
        >
          English (US)
        </div>
        <div
          class="px-16 py-8 hover:bg-[#00000008] rounded-full cursor-pointer"
          :class="[$i18n.locale === 'de' && 'font-bold']"
          @click="updateLocale('de')"
        >
          Deutsch (DE)
        </div>
      </div>
    </template>
  </UiDropdown>
</template>

<script lang="ts" setup>
const languageCookie = useCookie("i18n_redirected");
function updateLocale(language: "en" | "de") {
  useNuxtApp().$i18n.locale.value = language;
  languageCookie.value = language;
}
</script>

<style></style>
